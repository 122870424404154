import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useGoalUrlConstructor } from '@atlassian/jira-issue-field-goals-common/src/index.tsx';
import { ItemWithEntryPointPopup } from '@atlassian/jira-item-with-popup/src/ui/item-with-entrypoint-popup/index.tsx';
import { SimpleInlineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/simple-inline-smart-link/index.tsx';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { goals_issueFieldGoalsReadviewFull_GoalsReadView$key as GoalsFragment } from '@atlassian/jira-relay/src/__generated__/goals_issueFieldGoalsReadviewFull_GoalsReadView.graphql';
import { useIntl } from '@atlassian/jira-intl';
import type { GoalsReadViewProps, ValidNonNullableSelectedGoalNode } from './types.tsx';
import { goalsPopupContentEntryPoint } from './popup-content/entrypoint.tsx';
import messages from './messages.tsx';

/**
 * The GoalsReadView will show a read only view of Goals field.
 * @param props [GoalsReadViewProps](./types.tsx)
 */
export const GoalsReadView = ({ fragmentRef, isTruncated }: GoalsReadViewProps) => {
	const { formatMessage } = useIntl();
	const constructGoalUrl = useGoalUrlConstructor();

	const { selectedGoals } = useFragment<GoalsFragment>(
		graphql`
			fragment goals_issueFieldGoalsReadviewFull_GoalsReadView on JiraGoalsField {
				selectedGoals(first: 1000) {
					# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
					...popupContent_issueFieldGoalsReadviewFull
					edges {
						node {
							id
							key
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { firstGoal, totalGoalsCount } = useMemo(() => {
		const nodes: ValidNonNullableSelectedGoalNode[] =
			selectedGoals?.edges
				?.map((edge) => edge?.node)
				?.filter((node): node is ValidNonNullableSelectedGoalNode => Boolean(node?.key)) ?? [];

		const transformedNodes = nodes.map((node) => ({
			...node,
			key: node.id,
			url: constructGoalUrl(node.key),
		}));

		return {
			firstGoal: transformedNodes[0],
			totalGoalsCount: transformedNodes.length,
		};
	}, [constructGoalUrl, selectedGoals?.edges]);

	if (totalGoalsCount === 0 || !selectedGoals) {
		return <EmptyFieldText />;
	}

	return (
		<ItemWithEntryPointPopup
			ItemComponent={<SimpleInlineSmartLink url={firstGoal.url} />}
			totalItems={totalGoalsCount}
			isTruncated={isTruncated}
			entryPoint={goalsPopupContentEntryPoint}
			entryPointParams={{}}
			entryPointRuntimeProps={{ fragmentRef: selectedGoals }}
			showMoreTooltip={formatMessage(messages.showMoreTooltip)}
			actionSubjectIdPrefix="goals"
		/>
	);
};
