import React, { useCallback, useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type {
	ContextualFields,
	IssueToCreate,
} from '@atlassian/jira-issue-table-inline-issue-create/src/common/types.tsx';
import InlineCreateForm, {
	type Props as InlineCreateFormProps,
} from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-form/index.tsx';
import type { OnCreateIssue } from '../../types.tsx';

export type Props = Omit<InlineCreateFormProps, 'onCreateIssue'> & {
	parentIssueAri?: string;
	siblingIssueId?: string;
	isIssueRankDescending: boolean;
	width: number;
	onCreateIssue: OnCreateIssue;
};

export const IssueCreateCell = ({
	parentIssueAri,
	siblingIssueId,
	depth,
	isIssueRankDescending,
	width,
	onCreateIssue,
	...props
}: Props) => {
	const formContextualFields = useMemo(
		() =>
			isIssueRankDescending
				? { rankAfterIssueId: siblingIssueId }
				: { rankBeforeIssueId: siblingIssueId },
		[isIssueRankDescending, siblingIssueId],
	);

	const handleIssueCreate = useCallback(
		(issueToCreate: IssueToCreate, contextualFields: ContextualFields) => {
			onCreateIssue(
				issueToCreate,
				{
					...contextualFields,
					parentIssueAri,
				},
				{
					insertAfter: false,
					insertNodeId: siblingIssueId,
				},
			);
		},
		[onCreateIssue, parentIssueAri, siblingIssueId],
	);

	return (
		<Box as="td" xcss={cellStyles}>
			{/* eslint-disable-next-line jira/react/no-style-attribute */}
			<Box xcss={boxStyles} style={{ width: `${width}px` }}>
				<InlineCreateForm
					contextualFields={formContextualFields}
					onCreateIssue={handleIssueCreate}
					depth={depth + 1}
					{...props}
				/>
			</Box>
		</Box>
	);
};

const cellStyles = xcss({
	height: '40px',
	left: 'space.0',
	position: 'sticky',
	zIndex: 'card',
});

const boxStyles = xcss({
	left: 'space.0',
	position: 'absolute',
	top: 'space.0',
});
