import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { xcss, Box } from '@atlaskit/primitives';
import { SimpleTag } from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import type { multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView$key as MultiVersionPickerFragment } from '@atlassian/jira-relay/src/__generated__/multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView.graphql';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import ListWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import messages from './messages.tsx';
import type { MultiVersionPickerReadViewProps, SelectedEdge } from './types.tsx';

/**
 * The MultiVersionPickerReadView will show a read only view of MultiVersionPicker field.
 * @param props [MultiVersionPickerReadViewProps](./types.tsx)
 */
export const MultiVersionPickerReadView = ({
	fragmentRef,
	isTruncated,
	isTruncatedWithHoverPopover,
}: MultiVersionPickerReadViewProps) => {
	const data = useFragment<MultiVersionPickerFragment>(
		graphql`
			fragment multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView on JiraMultipleVersionPickerField {
				selectedVersionsConnection(first: 1000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { formatMessage } = useIntl();

	const selectedOptions =
		data?.selectedVersionsConnection?.edges
			?.filter((edge: SelectedEdge) => Boolean(edge?.node?.name))
			.map((edge: SelectedEdge) => ({ name: edge?.node?.name || '', key: edge?.node?.id || '' })) ||
		[];

	if (!selectedOptions?.length) {
		return <EmptyFieldText />;
	}
	if (isTruncated) {
		return (
			<Box paddingBlock="space.050" xcss={isTruncatedWithHoverPopover && containerWidthStyles}>
				<ListWithPopup
					items={selectedOptions}
					ItemComponent={TagItem}
					PopupContentWrapper={TagItemPopupContentWrapper}
					showMoreTooltip={formatMessage(messages.showMoreTooltip)}
					isHoverPopoverEnabled={isTruncatedWithHoverPopover}
				/>
			</Box>
		);
	}

	return (
		<Box xcss={containerWithNegativeMarginOffset}>
			{selectedOptions.map((item: { key: string; name: string }) => (
				<SimpleTag key={item.key} text={item.name} />
			))}
		</Box>
	);
};

const containerWithNegativeMarginOffset = xcss({
	display: 'flex',
	width: '100%',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	// Tag has a built-in margin of 4px, so we use a negative side margin to allow tags to align with their parent.
	marginTop: 'space.0',
	marginRight: 'space.negative.050',
	marginBottom: 'space.0',
	marginLeft: 'space.negative.050',
});

const containerWidthStyles = xcss({
	width: '100%',
});
