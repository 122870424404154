import React from 'react';
import type { Props as InlineCreateFormProps } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/inline-create-form/index.tsx';
import type { OnCreateIssue } from '../types.tsx';
import { IssueCreateCell } from './issue-create-cell/index.tsx';

export type Props = Omit<InlineCreateFormProps, 'onCreateIssue' | 'contextualFields'> & {
	isFormVisible: boolean;
	parentIssueAri?: string;
	siblingIssueId?: string;
	isIssueRankDescending: boolean;
	width: number;
	onCreateIssue: OnCreateIssue;
};

export const ChildIssueCreateRow = ({ isFormVisible, ...props }: Props) => {
	return (
		isFormVisible && (
			<tr>
				<IssueCreateCell {...props} />
			</tr>
		)
	);
};
