import React from 'react';
import { jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { MessageDescriptor } from '@formatjs/intl';
import { Box, xcss } from '@atlaskit/primitives';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';

import { useIntl } from '@atlassian/jira-intl';
import type { goalGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/goalGroupHeader_nativeIssueTable.graphql';
import { GoalIcon } from '@atlassian/goals';
import { normalizeGoalAppearance } from '@atlassian/goals/icon';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export type Props = {
	goalGroupHeader: goalGroupHeader_nativeIssueTable$key | null;
};

const goalStatusToLabelMap: Record<string, MessageDescriptor> = {
	ARCHIVED: messages.statusLabelArchived,
	PENDING: messages.statusLabelPending,
	IN_PROGRESS: messages.statusLabelInProgress,
	COMPLETED: messages.statusLabelCompleted,
	DONE: messages.statusLabelCompleted,
	ON_TRACK: messages.statusLabelOnTrack,
	PAUSED: messages.statusLabelPaused,
	AT_RISK: messages.statusLabelAtRisk,
	OFF_TRACK: messages.statusLabelOffTrack,
	CANCELLED: messages.statusLabelCancelled,
};

const goalStatusToLozengeAppearanceMap: Record<string, ThemeAppearance> = {
	ARCHIVED: 'default',
	PENDING: 'default',
	IN_PROGRESS: 'inprogress',
	COMPLETED: 'success',
	DONE: 'success',
	ON_TRACK: 'success',
	PAUSED: 'moved',
	AT_RISK: 'moved',
	OFF_TRACK: 'removed',
	CANCELLED: 'removed',
};

/**
 *
 * @deprecated This component is deprecated and will be removed when jira_list_grouping_extraction is cleaned up.
 * Use @atlassian/jira-issue-table-grouping/src/ui/group-row/group-header/goal-group-header/index.tsx instead.
 */
export const GoalGroupHeader = ({ goalGroupHeader }: Props) => {
	const data = useFragment<goalGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment goalGroupHeader_nativeIssueTable on JiraJqlGoalsFieldValue {
				displayName
				goal {
					status
					score
				}
			}
		`,
		goalGroupHeader,
	);

	const { formatMessage } = useIntl();

	const displayName =
		data?.displayName ??
		formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.noGoalsDisplayNameIssueTermRefresh
				: messages.noGoalsDisplayName,
		);
	const status = formatMessage(goalStatusToLabelMap[data?.goal?.status || 'PENDING']);
	const appearance = normalizeGoalAppearance(data?.goal?.status || 'PENDING');

	return (
		<>
			{data?.goal && <GoalIcon size="16" appearance={appearance} goalIconKey="GOAL" />}
			<Box xcss={goalTextStyles}>{displayName}</Box>
			{data?.goal && (
				<Lozenge appearance={goalStatusToLozengeAppearanceMap[data.goal.status || 'PENDING']}>
					{status}
					{data.goal.score && ` - ${data.goal.score || ''}`}
				</Lozenge>
			)}
		</>
	);
};

const goalTextStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
