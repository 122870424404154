import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Badge from '@atlaskit/badge';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import type { storyPointsGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/storyPointsGroupHeader_nativeIssueTable.graphql.ts';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

export type Props = {
	storyPointsGroupHeader: storyPointsGroupHeader_nativeIssueTable$key | null;
};

/**
 *
 * @deprecated This component is deprecated and will be removed when jira_list_grouping_extraction is cleaned up.
 * Use @atlassian/jira-issue-table-grouping/src/ui/group-row/group-header/story-points-group-header/index.tsx instead.
 */
export const StoryPointsGroupHeader = ({ storyPointsGroupHeader }: Props) => {
	const data = useFragment<storyPointsGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment storyPointsGroupHeader_nativeIssueTable on JiraJqlNumberFieldValue {
				number
			}
		`,
		storyPointsGroupHeader,
	);

	const { formatMessage } = useIntl();

	return isVisualRefreshEnabled() ? (
		<Badge appearance="default">
			{typeof data?.number === 'number'
				? formatMessage(messages.storyPoints, {
						points: data?.number,
					})
				: formatMessage(messages.noStoryPoints)}
		</Badge>
	) : (
		<Tag
			text={
				typeof data?.number === 'number'
					? formatMessage(messages.storyPoints, {
							points: data?.number,
						})
					: formatMessage(messages.noStoryPoints)
			}
		/>
	);
};
