// Adapted from @atlassian/jira-issue-table/src/common/fields/assignee

import React, { memo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { AssigneeInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/index.tsx';
import { ReporterInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-reporter-inline-edit-full/src/ui/reporter/index.tsx';
import { SingleUserPickerInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-single-user-picker-inline-edit-full/src/ui/single-user-picker/index.tsx';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/ui/profilecard/async.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { user_nativeIssueTable_UserCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/user_nativeIssueTable_UserCell_fieldRef.graphql';
import type { user_nativeIssueTable_UserCellInternal_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/user_nativeIssueTable_UserCellInternal_fieldRef.graphql';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { KNOWN_COLUMN_TYPES } from '@atlassian/jira-issue-table-common/src/constants.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { ConditionalWrapper } from '../../conditional-wrapper/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';
import messages from './messages.tsx';

export type PropsOld = {
	accountId?: string;
	avatarUrl?: string;
	displayName?: string;
};

export const makeAsyncProfileCardWrapper =
	(accountId: undefined | string) => (children: ReactNode) => (
		<AsyncProfileCardNext accountId={accountId ?? ''} actions={[VIEW_PROFILE_ACTION]}>
			{children}
		</AsyncProfileCardNext>
	);

export type Props = {
	fieldRef: user_nativeIssueTable_UserCell_fieldRef$key;
	projectKey: string;
};

export const UserCell = memo<Props>(({ fieldRef, projectKey }) => {
	const { formatMessage } = useIntl();
	const data = useFragment<user_nativeIssueTable_UserCell_fieldRef$key>(
		graphql`
			fragment user_nativeIssueTable_UserCell_fieldRef on JiraSingleSelectUserPickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				fieldId
				type
				user {
					accountId
					picture
					name
				}
				...user_nativeIssueTable_UserCellInternal_fieldRef
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
			}
		`,
		fieldRef,
	);

	if (
		data.fieldId === 'assignee' ||
		data.fieldId === 'reporter' ||
		fg('jsc_inline_editing_field_refactor')
	) {
		return <UserCellInternal fieldRef={data} projectKey={projectKey} />;
	}

	const { type, user } = data;
	const { name, accountId, picture } = user ?? {};
	const displayText =
		name ??
		formatMessage(type !== KNOWN_COLUMN_TYPES.ASSIGNEE ? messages.anonymous : messages.unassigned);

	if (user != null && (name == null || accountId == null)) {
		return <ErrorCell cellType="user" reason="Missing required fields" />;
	}

	return (
		<ConditionalWrapper
			condition={accountId !== undefined}
			renderWrapper={makeAsyncProfileCardWrapper(accountId)}
		>
			<Wrapper>
				<Avatar src={picture} size="small" borderColor="transparent" name="" />
				<Text>{displayText}</Text>
			</Wrapper>
		</ConditionalWrapper>
	);
});

type UserCellInternalProps = {
	fieldRef: user_nativeIssueTable_UserCellInternal_fieldRef$key;
	projectKey: string;
	/** Whether the menu should use a portal, and where it should attach */
	menuPortalTarget?: HTMLElement;
};

export const UserCellInternal = ({ fieldRef, projectKey }: UserCellInternalProps) => {
	const { formatMessage } = useIntl();
	const data = useFragment<user_nativeIssueTable_UserCellInternal_fieldRef$key>(
		graphql`
			fragment user_nativeIssueTable_UserCellInternal_fieldRef on JiraSingleSelectUserPickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				fieldId
				type
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled) # defined in ../../../graphql/schema.graphql
				...assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef
				...reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef
				...singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isAssigneeReporter = data.fieldId === 'assignee' || data.fieldId === 'reporter';
	const isCreatorField = data.fieldId === 'creator';

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	const isInlineEditingSupported = isAssigneeReporter || isInlineEditingExtendedFieldSupportEnabled;

	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	// We can also disable editability of the creator field as it is never editable.
	let isEditable: boolean | null | undefined;
	if (fg('jsc_inline_editing_field_refactor')) {
		isEditable =
			'lazyIsEditableInIssueView' in data && isInlineEditingSupported && !isCreatorField
				? data.lazyIsEditableInIssueView
				: false;
	} else {
		isEditable = 'lazyIsEditableInIssueView' in data ? data.lazyIsEditableInIssueView : false;
	}
	useLazyLoadEditability(data.id, isEditable);

	switch (data.fieldId) {
		case 'assignee': {
			return (
				<AssigneeInlineEditViewWithIsEditable
					attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
					spacing="default"
					editViewPopup
					editViewPopupAlignBlock="center"
					fragmentRef={data}
					isEditable={isEditable ?? false}
					menuPosition="fixed"
					readViewFitContainerHeight
					projectKey={toProjectKey(projectKey)}
				/>
			);
		}
		case 'reporter': {
			return (
				<ReporterInlineEditViewWithIsEditable
					attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
					spacing="default"
					editViewPopup
					editViewPopupAlignBlock="center"
					fragmentRef={data}
					isEditable={isEditable ?? false}
					menuPosition="fixed"
					readViewFitContainerHeight
				/>
			);
		}
		default: {
			const noneOptionLabel = isCreatorField ? formatMessage(messages.anonymous) : undefined;
			return (
				<SingleUserPickerInlineEditViewWithIsEditable
					attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
					spacing="default"
					editViewPopup
					editViewPopupAlignBlock="center"
					fragmentRef={data}
					isEditable={isEditable ?? false}
					menuPosition="fixed"
					readViewFitContainerHeight
					noneOptionLabel={noneOptionLabel}
				/>
			);
		}
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	paddingLeft: token('space.100'),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	cursor: 'default',
	marginLeft: token('space.negative.025'),
});
