import React, {
	useRef,
	createContext,
	useContext,
	type PropsWithChildren,
	useCallback,
	useMemo,
} from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props as AsyncColumnPickerProps } from '@atlassian/jira-issue-table-column-picker/src/ui/main.tsx';
import type { IFCConfigType } from '../types.tsx';
import type { Column } from '../../../types.tsx';
import { COLUMN_ID_INTERNAL_COLUMN } from '../../../constants.tsx';
import { TEMP_CREATE_FIELDSET_ID } from '../column-picker-footer/utils.tsx';

type IFCConfigContextType = {
	ifcConfig: IFCConfigType;
	addFieldToColumns?: (fieldSetId: string, index?: number, onErrorCallback?: () => void) => void;
};

export const IFCConfigContext = createContext<IFCConfigContextType>({ ifcConfig: undefined });

export const IFCConfigContextProvider = ({
	children,
	ifcConfig,
	columns,
	onColumnsChange,
}: PropsWithChildren<{
	ifcConfig: IFCConfigType;
	columns: Column[];
	onColumnsChange: AsyncColumnPickerProps['onChange'];
}>) => {
	const columnRef = useRef(columns);

	if (columnRef.current !== columns) {
		columnRef.current = columns;
	}

	const addFieldToColumns = useCallback(
		(fieldSetId: string, index?: number, onErrorCallback?: () => void) => {
			const columnIds = columnRef.current
				?.map(({ id }) => id)
				?.filter(
					(id) => !id.startsWith(COLUMN_ID_INTERNAL_COLUMN) && id !== TEMP_CREATE_FIELDSET_ID,
				);

			const insertIndex = index == null ? columnIds.length : index;
			columnIds.splice(insertIndex, 0, fieldSetId);

			onColumnsChange?.({ columnIds, retries: 3, onErrorCallback });
		},
		[onColumnsChange],
	);

	const value = useMemo(
		() => ({
			ifcConfig,
			addFieldToColumns,
		}),
		[ifcConfig, addFieldToColumns],
	);

	return <IFCConfigContext.Provider value={value}>{children}</IFCConfigContext.Provider>;
};

export const useIFCConfigContext = () => {
	return useContext(IFCConfigContext);
};

export const IFCConfigContextProviderComponent = componentWithCondition(
	() => fg('jira_inline_field_config_gate'),
	IFCConfigContextProvider,
	({ children }: PropsWithChildren<{ ifcConfig: IFCConfigType }>) => children,
);
