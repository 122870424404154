import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import CategoryTag from '@atlassian/jira-business-categories/src/ui/category-form/category-tag/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { categoryGroupHeader_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/categoryGroupHeader_nativeIssueTable.graphql';
import messages from './messages.tsx';

export type Props = {
	categoryGroupHeader: categoryGroupHeader_nativeIssueTable$key | null;
};

/**
 *
 * @deprecated This component is deprecated and will be removed when jira_list_grouping_extraction is cleaned up.
 * Use @atlassian/jira-issue-table-grouping/src/ui/group-row/group-header/category-group-header/index.tsx instead.
 */
export const CategoryGroupHeader = ({ categoryGroupHeader }: Props) => {
	const data = useFragment<categoryGroupHeader_nativeIssueTable$key>(
		graphql`
			fragment categoryGroupHeader_nativeIssueTable on JiraJqlOptionFieldValue {
				displayName
				option {
					color {
						colorKey
					}
				}
			}
		`,
		categoryGroupHeader,
	);

	const { formatMessage } = useIntl();

	return data != null ? (
		<CategoryTag
			categoryColor={data?.option?.color?.colorKey ?? null}
			categoryName={data?.displayName}
			isInteractive={false}
		/>
	) : (
		<Text>{formatMessage(messages.noCategory)}</Text>
	);
};
