import React, { useCallback, useMemo, type ReactElement } from 'react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ArrowDownIconOld from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIconOld from '@atlaskit/icon/glyph/arrow-up';
import ArrowDownIcon from '@atlaskit/icon/utility/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/utility/arrow-up';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { SORT_ASC, SORT_DESC, SORT_NONE } from '../../../../../../common/constants.tsx';
import type { Column } from '../../../../../../common/types.tsx';
import messages from './messages.tsx';

type Props = {
	column: Column;
	onFocus: () => void;
	onBlur: () => void;
};

type IconProps = {
	children: ReactElement;
};

const ConditionalIconContainer = ({ children }: IconProps) => {
	if (isVisualRefreshEnabled()) {
		return <Box xcss={iconContainerStyles}>{children} </Box>;
	}
	return children;
};

const useAccessibleLabel = ({ ascText, currentSorting, descText, title }: Column) => {
	const { formatMessage } = useIntl();

	const labelSuffix = useMemo(() => {
		switch (currentSorting) {
			case SORT_ASC:
				return ascText ?? false
					? formatMessage(messages.sortedTooltip, { sortDescription: ascText })
					: formatMessage(messages.sortedDefaultAscendingTooltip);
			case SORT_DESC:
				return descText ?? false
					? formatMessage(messages.sortedTooltip, { sortDescription: descText })
					: formatMessage(messages.sortedDefaultDescendingTooltip);
			default:
				return ascText ?? false
					? formatMessage(messages.sortPromptTooltip, { sortDescription: ascText })
					: formatMessage(messages.sortPromptDefaultTooltip);
		}
	}, [ascText, currentSorting, descText, formatMessage]);

	return useMemo(() => `${title} • ${labelSuffix}`, [labelSuffix, title]);
};

export const SortButton = ({ column, onFocus, onBlur }: Props) => {
	const label = useAccessibleLabel(column);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		const nextDirection = column.currentSorting === SORT_ASC ? SORT_DESC : SORT_ASC;

		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		const attributes = {
			fieldType: column.type,
			sortOrder: nextDirection,
		};

		fireUIAnalytics(event, 'sortOrderChange', attributes);

		column.onSortOrderChanged?.(column.jqlTerm, nextDirection);
	}, [column, createAnalyticsEvent]);

	const Icon = column.currentSorting === SORT_DESC ? ArrowDownIcon : ArrowUpIcon;

	return isVisualRefreshEnabled() ? (
		<IconButton
			icon={column.currentSorting === SORT_DESC ? ArrowDownIcon : ArrowUpIcon}
			label={label}
			spacing="compact"
			appearance="subtle"
			isTooltipDisabled={false}
			onClick={onClick}
			onFocus={onFocus}
			onBlur={onBlur}
			testId="native-issue-table.ui.issue-table.header.header-cell.header-cell-actions.sort-button.icon-button"
		/>
	) : (
		<Tooltip content={label} delay={0} ignoreTooltipPointerEvents>
			<Button
				appearance="subtle"
				spacing="compact"
				iconBefore={
					<ConditionalIconContainer>
						{column.currentSorting === SORT_DESC ? (
							<Icon
								label={label}
								color="currentColor"
								LEGACY_fallbackIcon={ArrowDownIconOld}
								LEGACY_size="small"
							/>
						) : (
							<Icon
								label={label}
								color="currentColor"
								LEGACY_fallbackIcon={ArrowUpIconOld}
								LEGACY_size="small"
							/>
						)}
					</ConditionalIconContainer>
				}
				isSelected={column.currentSorting !== SORT_NONE}
				onClick={onClick}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		</Tooltip>
	);
};

const iconContainerStyles = xcss({
	margin: 'space.025',
});
